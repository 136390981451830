import Me from "./Me";

function Languages(props) {
	let className = "hide-section"
	if (props.enabled) {
		className = "";
	}

	return (
		<section id="languages" className={className}>
			<h2><label htmlFor="section-4">Languages</label></h2>
			<ul className="no-bullets">
				{Me.languages.map((language, index) => {
					var className = language.skillLevel === Me.maxSkillLevel ? "slider full" : "slider";

					return (
						<li key={index}>
							<span className="language">{language.name}</span>
							<progress className={className} value={language.skillLevel} max={Me.maxLanguageLevel} />
						</li>
					);
				})}
			</ul>
		</section>
	)
}

export default Languages;
