function AdditionalLinks(props) {
	let className = "hide-section"
	if (props.enabled) {
		className = "";
	}

	return (
		<section id="additional-links" className={className}>
			<h2><label htmlFor="section-5">Additional Links</label></h2>
			<ul className="no-bullets educert">
				<li>
					<a
						rel="external author"
						href="https://github.com/aaron-hardin"
						><i className="fa-brands fa-github"></i> aaron-hardin</a>
				</li>
				<li>
					<a
						rel="external author"
						href="https://www.linkedin.com/in/aaronhardin90/"
						><i className="fa-brands fa-linkedin"></i> aaronhardin90</a>
				</li>
			</ul>
		</section>
	)
}

export default AdditionalLinks;
