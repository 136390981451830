import Me from "./Me";

function TechnicalSkills(props) {
	let className = "hide-section"
	if (props.enabled) {
		className = "";
	}

	// Sort them just in case
	const sortedSkills = Me.skills.sort((a,b) => b.skillLevel - a.skillLevel);

	return (
		<section id="technical-skills" className={className}>
			<h2><label htmlFor="section-2">Technical Skills</label></h2>
			<ul className="no-bullets" id="skills">
				{sortedSkills.map((skill, index) => {
					var className = skill.skillLevel === Me.maxSkillLevel ? "slider full" : "slider";

					return (
						<div key={index}>
							<li>{skill.name}</li>
							<li><progress className={className} value={skill.skillLevel} max={Me.maxSkillLevel} /></li>
						</div>
					);
				})}
			</ul>
		</section>
	)
}

export default TechnicalSkills;
