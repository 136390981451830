import React from 'react';

/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const Theme1 = React.lazy(() => import('./Theme1'));
const Theme2 = React.lazy(() => import('./Theme2'));

function ThemeSelector(props) {
  return <>
    {/* Conditionally render theme, based on the current client context */}
    <React.Suspense fallback={<></>}>
      {window.location.pathname !== '/printable' && <Theme1 />}
      {window.location.pathname === '/printable' && <Theme2 />}
    </React.Suspense>
    {/* Render children immediately! */} 
    {props.children}
  </>
}

export default ThemeSelector;