import { useState } from 'react';

import Me, { technologies } from "./Me";

function Projects(props) {
	const [recents, setRecents] = useState(true);
	const [filters, setFilters] = useState([]);

	let className = "hide-section"
	if (props.enabled) {
		className = "";
	}

	// Sort them just in case
	const recentThreshold = new Date();
	recentThreshold.setFullYear(recentThreshold.getFullYear() - 1);
	const sortedProjects = Me.projects
		.filter(p => {
			if (!(!recents || (!!p.lastActive && new Date(p.lastActive) > recentThreshold))) 
				return false;

			if (filters.length > 0) {
				if (p.technologies) {
					if (!p.technologies.some(t => filters.includes(t.displayName))) {
						return false;
					}
				} else {
					return false; // project didn't list any tech but we are filtering
				}
			}

			return true;
		})
		// TODO: need to cast to date? if so, how can we ensure that it only happens once
		.sort((a,b) => b.lastActive - a.lastActive);

	return (
		<section id="projects" className={className}>
			<h2><label htmlFor="projects">Projects</label></h2>
			<div className="hide-sm">
				<h3>Filter</h3>
				<div id="projects-filter">
					<input type="checkbox" checked={recents} onChange={() => setRecents(!recents)} id="recentFilter"/>
					<label htmlFor="recentFilter">Recent</label>
					{Object.keys(technologies).map(tKey => {
						const displayName = technologies[tKey].displayName;
						const tClass = filters.includes(displayName) ? "filtering pill" : "pill";

						return (
							<span className={tClass} key={tKey} onClick={() => {
								const newArr = filters.slice(); // copy existing
								if (filters.includes(displayName)) {
									newArr.splice(filters.indexOf(displayName), 1);
								} else {
									newArr.push(displayName);
								}
								setFilters(newArr);
							}}>{displayName}</span>
						);
					})}
				</div>
			</div>
			<div id="projects-list">
				{sortedProjects.map(project => {
					var className = "";

					return (
						<div className={className} key={project.name}>
							<h3>{!!project.link
								? <a href={project.link}>{project.name}</a>
								: <>{project.name}</>
							}</h3>
							{project.technologies && <span>
								{project.technologies.map((t, tIndex) => {
									const tClass = tIndex > 2 ? "hide-sm pill" : "pill";

									return (
										<span className={tClass} key={t.displayName}>{t.displayName}</span>
									);
								})}
								</span>
							}
							{project.description && <p>{project.description}</p>}
						</div>
					);
				})}
			</div>
		</section>
	)
}

export default Projects;
