function AboutMe(props) {
	let className = "hide-section"
	if (props.enabled) {
		className = "";
	}

	return (
		<section id="about-me" className={className}>
			<h2><label htmlFor="about-me">About Me</label></h2>
			<p>
			I enjoy solving complex problems and learning new technologies. Luckily for me I have plenty of opportunities for both of these. There are wonderful things happening in the world of .NET now and many resources online for learning new languages and frameworks. As a Senior Application Developer at M-Files, I am constantly working on new applications built on top of our main product, many of which are custom integrations built to meet customer needs.
			</p>
			<p>
			For me, coding is much more than just a job. It's a way to connect to technology and push it further. It teaches us about ourselves, humanity as a whole, and if done properly it can be used as a tool to bring us all much closer together. I see technology as something that makes us more of who we are. If we are greedy then it helps us to be more greedy, if we are compassionate then it helps us to be more compassionate. I want to always work with a company that wants to use technology to help people have better lives.
			</p>
			<p>
			Specialties: C#, React
			</p>
			<p>
			Other interests: Go (golang), Rust, Docker
			</p>
		</section>
	)
}

export default AboutMe;
