const mfilesCompany = {
	name: "M-Files",
	url: "https://www.m-files.com/"
};

const donorDirectCompany = {
	name: "DonorDirect",
	url: "https://www.donordirect.com/"
};

const jobs = [
	{
		company: mfilesCompany,
		department: "Customer Success",
		title: "Senior Application Developer",
		startDate: "10/2019",
		endDate: null,
		notes: [
			"Migrated CI from TeamCity to GitLab CI, utilizing Docker for consistent build environment.",
			"Conducted code reviews for junior developers.",
			"Integrated with Azure to expand customer usage.",
			"Built a Rust wrapper with extensions for M-Files gRPC API",
			"Built various command line utilities in Rust and C#"
		],
		technologies: "reactjs html css javascript csharp api rust grpc",
	},
	{
		company: mfilesCompany,
		department: "Customer Success",
		title: "Software Developer",
		startDate: "11/2017",
		endDate: "10/2019",
		notes: [
			"Extended M-Files UI capabilities with client side applications built using ReactJS/HTML/CSS.",
			"Designed and developed multiple global integrations for Fortune 100 company."
		],
		technologies: "reactjs html css javascript csharp api"
	},
	{
		company: donorDirectCompany,
		department: "Product Development",
		title: "Software Developer",
		startDate: "05/2015",
		endDate: "11/2017",
		notes: [
			"Migrated legacy CRM system using WCF to ASP.NET REST API with ReactJS SPA frontend.",
			"Designed and developed drag-and-drop storefront CMS using .NET Core and ReactJS.",
			"Enhanced storefront search to operate 10x faster than legacy product.",
			"Designed and developed importer, including support for customer-specific features via plugins."
		],
		technologies: "reactjs html css javascript csharp api"
	},
	{
		company: mfilesCompany,
		department: "Services",
		title: "Software Developer",
		startDate: "05/2014",
		endDate: "05/2015",
		notes: [
			"Extended M-Files capabilities with server side applications to fulfill customer specific needs using C#.",
			"Deployed TeamCity build server to version and release applications.",
			"Designed and developed integration with Microsoft Dynamics GP."
		],
		technologies: "reactjs html css javascript csharp api"
	},
	{
		company: mfilesCompany,
		department: "Services",
		title: "Technical Consultant",
		startDate: "04/2013",
		endDate: "05/2014",
		notes: [
			"Configured M-Files ECM system after requirements/design sessions with customer.",
			"Extended system as needed using VBScript/JS/C#."
		],
		technologies: "reactjs html css javascript csharp api"
	}
];

const languages = [
	{
		name: "English",
		skillLevel: 5
	},
	{
		name: "Japanese",
		skillLevel: 2
	}
];

const skills = [
	{
		name: "C#",
		skillLevel: 5
	},
	{
		name: "ReactJS",
		skillLevel: 5
	},
	{
		name: "Rust",
		skillLevel: 4
	},
	{
		name: "SQL",
		skillLevel: 4
	},
	{
		name: "JavaScript",
		skillLevel: 4
	},
	{
		name: "HTML",
		skillLevel: 4
	},
	{
		name: "CSS",
		skillLevel: 3
	},
	{
		name: "Azure",
		skillLevel: 3
	},
	{
		name: "Docker",
		skillLevel: 3
	},
];

const technologies = {
	csharp: {
		displayName: "C#",
		subItems: {
			mvc: {
				displayName: "MVC"
			}
		}
	},
	rust: {
		displayName: "Rust",
		subItems: {
			yew: {
				displayName: "Yew"
			}
		}
	},
	javascript: {
		displayName: "JS",
		subItems: {
			react: {
				displayName: "React.JS"
			}
		}
	},
	wasm: {
		displayName: "Wasm"
	},
	go: {
		displayName: "golang"
	},
	grpc: {
		displayName: "gRPC"
	}
}

const projects = [
	{
		name: "aaronhardin.dev",
		description: "This website",
		lastActive: "2023-06-21T00:00:00.000Z",
		technologies: [technologies.javascript, technologies.javascript.subItems.react],
	},
	{
		name: "M-Files gRPC Client",
		description: "Rust client implementation of M-Files gRPC API. Includes convenience methods and example binary.",
		lastActive: "2023-06-21T00:00:00.000Z",
		technologies: [
			technologies.rust,
			technologies.grpc,
		],
	},
	{
		name: "BudgetMeister",
		description: "Budgeting application with support for multiple currencies.",
		lastActive: "2023-06-01T00:00:00.000Z",
		technologies: [
			technologies.csharp,
			technologies.csharp.subItems.mvc,
			technologies.javascript,
			technologies.javascript.subItems.react
		],
	},
	{
		name: "Loan Payoff",
		description: "App to show which order you should pay loans off in. HTML page with all of the logic written in Rust.",
		link: "https://github.com/aaron-hardin/loan-payoff",
		lastActive: "2022-02-20T00:00:00.000Z",
		technologies: [
			technologies.rust,
			technologies.rust.subItems.yew,
			technologies.wasm,
		]
	},
	{
		name: "gopher-money",
		description: "Go utility for handling currencies. Supports currency conversion and formatting.",
		link: "https://github.com/aaron-hardin/gopher-money",
		lastActive: "2020-11-09T00:00:00.000Z",
		technologies: [
			technologies.go,
		]
	}
];

const Me = {
	objective: "Senior Application Developer with strong full-stack development experience creating large-scale integrations between enterprise systems.",
	jobs,
	languages,
	maxLanguageLevel: 5,
	skills,
	maxSkillLevel: 5,
	email: "coding@aaronhardin.dev",
	projects,
};

export { technologies };
export default Me;