import Me from "./Me";

function Experience(props) {
	let className = "hide-section"
	if (props.enabled) {
		className = "";
	}

	return (
		<section id="professional-experience" className={className}>
			<h2><label htmlFor="section-1">Experience</label></h2>
			<div id="experience-container">
				{Me.jobs.map((job, index) => {
					return (
						<section className="position" key={index}>
							<h3>
								<a href={job.company.url}><span>{job.company.name}</span></a>
								<span className="dept">{job.department}</span>
							</h3>
							<h4>
								<span>{job.title}</span>
								<span className="dates">
									<time>{job.startDate}</time> - <time>{job.endDate || "Present"}</time>
								</span>
							</h4>
							<ul>
								{job.notes && job.notes.map((note, noteIndex) => <li key={noteIndex}>{note}</li>)}
							</ul>
						</section>
					)
				})}
			</div>
		</section>
	)
}

export default Experience;
