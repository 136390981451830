function Education(props) {
	let className = "hide-section"
	if (props.enabled) {
		className = "";
	}

	return (
		<section id="education" className={className}>
			<h2><label htmlFor="section-3">Education</label></h2>
			<ul className="no-bullets educert">
				<li>
					<span>The University of Texas at Dallas</span>
					<ul>
						<li>M.S. Computer Science</li>
						<li>B.S. Computer Science</li>
						<li>B.S. Electrical Engineering</li>
					</ul>
				</li>
			</ul>
		</section>
	)
}

export default Education;
