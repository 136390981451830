import { useState } from 'react';

import AboutMe from './AboutMe';
import AdditionalLinks from './AdditionalLinks';
import Education from './Education';
import Experience from './Experience';
import Languages from './Languages';
import Projects from './Projects';
import TechnicalSkills from './TechnicalSkills';

function App() {
	const [enabled, setEnabled] = useState("AboutMe");
	const [menuExpanded, setMenuExpanded] = useState("MenuExpanded");

	return (
		<div className="App">
			<main id="resume" className="with-sidebar">
				<div id="nav-menu">
					<div id="profile-pic">
						<img src="/me.jpg" width="200" alt="profile" />
						<div>
							Aaron Hardin
						</div>
					</div>
					<div id="icon-garden">
						<ul className="no-bullets horizontal-list">
							<li>
								<a
									rel="external author"
									href="https://github.com/aaron-hardin"
									><i className="fa-brands fa-github"></i></a>
							</li>
							<li>
								<a
									rel="external author"
									href="https://www.linkedin.com/in/aaronhardin90/"
									><i className="fa-brands fa-linkedin"></i></a>
							</li>
							<li>
								<a
									rel="alternate author"
									href="/printable"
									><i className="fa-solid fa-print"></i></a>
							</li>
						</ul>
					</div>
					<button id="nav-toggle" aria-expanded={menuExpanded} onClick={() => setMenuExpanded(!menuExpanded)}>
						<svg viewBox="0 0 32 32">
							<path d="M3,3 29,3 M3,16 29,16 M3,29 29,29"></path>
						</svg>
						Menu
					</button>
					<ul>
						{/* ideally, the 'clickable' width of these should be based on the contents, not the width of the nav-menu */}
						<li className={enabled === "AboutMe" ? "selected" : ""} onClick={() => setEnabled("AboutMe")}>About Me</li>
						<li className={enabled === "Experience" ? "selected" : ""} onClick={() => setEnabled("Experience")}>Experience</li>
						<li className={enabled === "Projects" ? "selected" : ""} onClick={() => setEnabled("Projects")}>Projects</li>
						<li className={enabled === "TechnicalSkills" ? "selected" : ""} onClick={() => setEnabled("TechnicalSkills")}>Technical Skills</li>
						<li className={enabled === "Education" ? "selected" : ""} onClick={() => setEnabled("Education")}>Education</li>
						<li className={enabled === "Languages" ? "selected" : ""} onClick={() => setEnabled("Languages")}>Languages</li>
						<li className={enabled === "AdditionalLinks" ? "selected" : ""} onClick={() => setEnabled("AdditionalLinks")}>Find Me At</li>
					</ul>
				</div>
				<div>
					<img className="cover" src="/cover.jpg" alt="mountain with sakura" />

					<div id="inner-wrapper">
						<AboutMe enabled={enabled === "AboutMe"} />

						<Experience enabled={enabled === "Experience"} />

						<Projects enabled={enabled === "Projects"} />

						<TechnicalSkills enabled={enabled === "TechnicalSkills"} />

						<Education enabled={enabled === "Education"} />

						<Languages enabled={enabled === "Languages"} />

						<AdditionalLinks enabled={enabled === "AdditionalLinks"} />
					</div>
				</div>
			</main>
		</div>
	);
}

export default App;
