import Me from "./Me";

function Header() {
	return (
		<header>
			<h1><label htmlFor="section-0">Aaron Hardin</label></h1>
			<p id="objective">
				<span>{Me.objective}</span>
			</p>
		</header>
	)
}

export default Header;
