import AdditionalLinksLegacy from './AdditionalLinksLegacy';
import Education from './Education';
import Experience from './Experience';
import Header from './Header';
import Languages from './Languages';
import Me from "./Me";
import TechnicalSkills from './TechnicalSkills';

function AppPrintable() {
	return (
		<div className="App">
			<main id="resume">
				<div>
					<Header />

					<Experience />
				</div>
				<div>
					<address id="contact" className="contact-2">
						<div id="location">
							<span>Setagaya-ku</span>, <span>Tokyo</span>
						</div>
						<div>
							<a href={`mailto:${Me.email}`} id="email">{Me.email}</a>
						</div>
						<div>
							<a href="https://www.aaronhardin.dev" id="website">www.aaronhardin.dev</a>
						</div>
					</address>

					<TechnicalSkills />

					<Education />

					<Languages />

					<AdditionalLinksLegacy />
				</div>
			</main>
		</div>
	);
}

export default AppPrintable;
